import { graphql } from 'gatsby';
import React from 'react';

import { Layout } from '../../containers/layout';
import { Modal } from '../../containers/modal';
import { PraxisTemplate } from '../../pageTemplates/praxis';
import { GraphQLData } from '../../types';

interface PraxisProps {
  data: GraphQLData;
  location: any;
}

const Praxis = ({ location, data }: PraxisProps) => {
  return (
    <Layout>
      <PraxisTemplate data={data} location={location} />
      <Modal team />
      <Modal services level="2" />
    </Layout>
  );
};

export const query = graphql`
  query PraxisEN {
    strapi {
      praxi {
        localizations {
          meta {
            title
            description
          }
          whyTitle
          therapistTitle
          subheader
          therapistDescription
          warumCarousel {
            id
            description
            title
            image {
              alternativeText
              caption
              url
              file {
                ...Image
              }
            }
          }
        }
      }
      therapists(sort: "order:ASC") {
        localizations {
          id
          title
          forename
          surname
          image {
            alternativeText
            url
            file {
              ...Image
            }
          }
        }
      }
    }
  }
`;

export default Praxis;
